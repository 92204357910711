<template>
    <!-- v-if="returnAbility('backup:run')"  -->
    <div>
        <v-row>
            <v-col md="3">
                <v-btn style="margin: 30px;" color="green" dark @click="taxesIndex" >
                   
                   {{ $t('Tax Code') }}
               </v-btn>
            </v-col>
            <!-- v-if="returnAbility('final_grade:index')" -->
            <v-col md="3" >
                <v-btn style="margin: 30px;" color="green" dark @click="taxesTypeIndex" >
                   
                   {{ $t('Taxes Type') }}
               </v-btn>
            </v-col>
   

        </v-row>
      
    </div>
</template>
<script>

export default {
    components: {

    },
    data() {
        return {
           
        };
    },
    methods: {
        taxesIndex() {
              this.$router.push("/taxesIndex")
        },
        taxesTypeIndex() {
            this.$router.push("/taxesTypeIndex");
        }
 
    },
    computed: {
      
    },
    created() {
    }
};
</script>